export const workExpCompareKeys = ["company", "designation"];
export const educationCompareKeys = ["course", "institution", "start_year", "end_year"];


export const EXPERT_API_RESPONSES = {
    PLEASE_ENTER_URL: "Please enter a URL",
    ERROR_OCCURRED_WHILE_FETCHING_DATA: "An error occurred while fetching data",
    FAILED_TO_FETCH_DATA: "Failed to fetch data",
    FAILED_TO_UPDATE_DATA: "Failed to update data",
    ERROR_OCCURED_WHILE_UPDATING_DATA: "An error occurred while updating data",
    COMPANY_IS_REQUIRED: "Company is required",
    DESIGNATION_IS_REQUIRED: "Designation is required",
    START_DATE_IS_REQUIRED: "Start date is required",
    END_DATE_IS_REQUIRED: "End date is required",
    CURRENTLY_WORKS_HERE_IS_REQUIRED: "Currently works here is required",
    START_DATE_SHOULD_BE_LESS_THAN_END_DATE: "Start date should be less than end date",
    COURSE_IS_REQUIRED: "Course is required",
    INSTITUTION_IS_REQUIRED: "Institution is required",
    START_YEAR_IS_REQUIRED: "Start year is required",
    END_YEAR_IS_REQUIRED: "End year is required",
    START_YEAR_SHOULD_BE_LESS_THAN_END_YEAR: "Start year should be less than end year",
    EXPERT_NOT_FOUND: "Expert not found",
    EXPERT_ALREADY_EXISTS: "Expert already exists",
    EXPERT_ADDED_SUCCESSFULLY: "Expert added successfully",
    EXPERT_UPDATED_SUCCESSFULLY: "Expert updated successfully",
}