import { Box, Grid } from "@mui/material";
import { HookRadioButton } from "../../../../../atoms/form-fields/SLFieldRadioButton";
import FormCancelSubmitBtns from "../../../../../atoms/formCancelSubmitBtns";
import { inputRowCommonStyles } from "../../../../../common/formStyles";
import { useHookFormContext } from "../../../../../utils/hooks/useHookFormContext";
import { ratingOptions } from "./helper";
import { RequiredTextField } from "../../../../../molecules/input-components/RequiredTextField";

export const inputRow = {
  padding: "10px 5px",
};

interface Props {
  handleClose: () => void;
}

const Fields = ({ handleClose }: Props) => {
  const { registerState } = useHookFormContext();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: "10px"
      }}
    >
      <Grid item xs={12} sx={inputRowCommonStyles}>
        <HookRadioButton
          {...registerState("relevancy_rating")}
          label="For Relevancy: *"
          rules={{
            required: { value: true, message: "This field is required" },
          }}
          radioGroupProps={{
            style: { display: "flex", gap: "1rem" },
          }}
          fields={ratingOptions}
        />
      </Grid>
      <Grid sx={inputRowCommonStyles} item xs={12}>
        <RequiredTextField
          label="Remark (Optional)"
          id="relevancy_remark"
          multiline
          max={250}
          required={false}
          minRows={4}
          maxRows={4}
        />
      </Grid>
      <Grid item xs={12}>
       <hr style={{ color: "#e0e0e0" }} />
      </Grid>
      
      <Grid item xs={12} sx={inputRowCommonStyles}>
        <HookRadioButton
          {...registerState("seniority_rating")}
          label="For Seniority: "
          radioGroupProps={{
            style: { display: "flex", gap: "1rem" },
          }}
          fields={ratingOptions}
          enableDeselect
        />
      </Grid>
      <Grid sx={inputRow} item xs={12}>
        <RequiredTextField
          label="Remark (Optional)"
          id="seniority_remark"
          multiline
          max={250}
          required={false}
          minRows={4}
          maxRows={4}
        />
      </Grid>
      <FormCancelSubmitBtns handleClose={handleClose} />
    </Grid>
  );
};

export default Fields;
