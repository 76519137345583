import React, { useEffect, useState } from 'react'
import QuickSearchDropDown from './quicksearch-dropdown'
import { Box } from '@mui/material'
import QuickSearchBar from './search-bar'

interface QuickSearchComponentProps {
    searchLabel: string
    onSearch: (searchValue: string) => void
    searchValue?: string
    searchIn: string[]
    setSearchIn: (searchIn: string[]) => void
    forceSearch: () => void
}

const QuickSearchComponent = ({ searchLabel, onSearch, searchValue, searchIn, setSearchIn, forceSearch }: QuickSearchComponentProps) => {
    const [dropdown, setDropdown] = useState(false);
    const [autoOpenStatus, setAutoOpenStatus] = useState(false);
    const [selectedKeywords, setSelectedKeywords] = useState<Record<string, boolean>>({});
    const [history, setHistory] = useState<Record<string, boolean>>({});
    const checkChanged = () => {
        return !areEqual(selectedKeywords, history);
    }
    const handleSearch = (searchValue: string) => {
        let flag = 0;
        if (checkChanged() && dropdown) {
            flag = 1;
            const newParsedSearchIn = Object.keys(selectedKeywords);
            setSearchIn(newParsedSearchIn);
        }
        setDropdown(false)
        setAutoOpenStatus(false);
        setHistory(selectedKeywords);
        onSearch(searchValue);
        if (flag) {
            forceSearch();
        }
    }
    const areEqual = (obj1: Record<string, boolean>, obj2: Record<string, boolean>): boolean => {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

        return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
    };
    const handleReset = () => {
        setSelectedKeywords({});
        setHistory({});
        setSearchIn([]);
        setDropdown(false);
        setAutoOpenStatus(false);
    }
    const handleDropDown = () => {
        if (checkChanged() && dropdown) {
            const newParsedSearchIn = Object.keys(selectedKeywords);
            setSearchIn(newParsedSearchIn);
        }
        setDropdown(!dropdown)
        setAutoOpenStatus(!dropdown);
        setHistory(selectedKeywords);
    }
    const handleClose = () => {
        if (checkChanged() && dropdown) {
            const newParsedSearchIn = Object.keys(selectedKeywords);
            setSearchIn(newParsedSearchIn);
        }
        setDropdown(!dropdown)
        setHistory(selectedKeywords);
    }
    const handleAutoOpen = () => {
        if (autoOpenStatus) {
            setDropdown(true);
        }
    }
    return (
        <div style={{ position: "relative" }}>
            <div style={{ display: "flex", alignItems: "center", position: (dropdown ? "relative" : "relative"), zIndex: (dropdown ? 1001 : 0) }} onFocus={handleAutoOpen}>
                <QuickSearchDropDown handleReset={handleReset} autoOpenStatus={autoOpenStatus} selectedKeywords={selectedKeywords} setSelectedKeywords={setSelectedKeywords} history={history} setHistory={setHistory} handleDropDown={handleDropDown} dropdown={dropdown} setDropdown={setDropdown} parsedSearchIn={searchIn} setParsedSearchIn={setSearchIn} />
                <QuickSearchBar
                    placeholder={searchLabel}
                    onSearch={handleSearch}
                    showSearchIcon={false}
                    searchValue={searchValue || ""}
                    dropDown={dropdown}
                />
            </div>
            <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 999, backgroundColor: "rgba(0, 0, 0, 0.5)", transition: "opacity 0.3s ease-in-out, visibility 0.3s", opacity: dropdown ? 1 : 0, visibility: dropdown ? "visible" : "hidden" }} onClick={handleClose}></Box >
        </div>
    )
}

export default QuickSearchComponent