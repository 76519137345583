import { Box, Drawer, Input, TextField, Typography } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import { SIDEBAR_MOCK_ITEMS } from './quick-search.constants'

interface FilterDrawerProps {
    openDrawer: boolean
    handleCloseDrawer: () => void
}

const dividerStyles = {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: "80%",
    height: "0.5px",
    backgroundColor: "rgba(0, 0, 0, 0.12)"
}

const divider = (<span style={dividerStyles}></span>)
const fullDivider = (<span style={{ ...dividerStyles, width: "100%" }}></span>)
const FilterDrawer = ({ openDrawer, handleCloseDrawer }: FilterDrawerProps) => {
    const [keywordData, setKeywordData] = useState<any>({});
    const onKeywordChange = (keyword: string, value: string) => {
        if (value === "") {
            setKeywordData({ ...keywordData, [keyword]: undefined });
            return;
        }
        setKeywordData({ ...keywordData, [keyword]: value });
    }
    const renderFilterDrawer = (keyword: any, index: number) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #e0e0e0", padding: "10px 0", gap: "1rem" }} key={index}>
                <Typography key={index} style={{ textTransform: "capitalize" }} >
                    {keyword} :
                </Typography>
                <TextField
                    label={keyword}
                    variant='outlined'
                    size='small'
                    value={keywordData[keyword] || ""}
                    onChange={(e) => onKeywordChange(keyword, e.target.value)}
                />
            </div>
        )
    }
    return (

        <Drawer anchor="right" open={openDrawer} onClose={handleCloseDrawer}>
            <Box sx={{ minWidth: 350 }}>
                <Typography variant="h5" sx={{ p: 2, textAlign: "center" }}>Filter Search</Typography>
                {fullDivider}
                <Typography variant="h6" sx={{ p: 2, textAlign: "center" }}>Keywords</Typography>
                {divider}
                <Box sx={{ p: 2 }}>
                    {SIDEBAR_MOCK_ITEMS.map(renderFilterDrawer)}
                </Box>
            </Box>
        </Drawer>
    )
}

export default FilterDrawer