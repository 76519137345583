import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, TextField, Box, Grid, Typography, Checkbox, FormControlLabel, Collapse, Divider, CardContent, Card, IconButton } from "@mui/material";
import PageLayout from "../../atoms/page-layout";
import ExpertsListPageHeader from "../../molecules/app-bars/ExpertsList-Enrichment";
import { fetchExpertData, updateExpertData } from "./helper";
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExperienceForm from "./experience/experienceForm";
import EducationForm from "../../organisms/edit-expert/education/education-form";
import { educationCompareKeys, EXPERT_API_RESPONSES, workExpCompareKeys } from "./expert-list.constants";
import { useSnackbar } from "notistack";
import FullPageReview from "./fullPageReview";
import CheckIcon from "@mui/icons-material/Check";
import CardsLoadingScreen from "../../atoms/cardsLoader";
import dayjs from "dayjs";

interface WorkExperience {
    id?: number;
    company: string;
    location?: string;
    fk_company?: number;
    designation: string;
    job_description: string;
    division: string;
    start_date: string;
    end_date: string;
    currently_works_here: boolean;
    initial_company?: string;
}

interface Education {
    course: string;
    start_year: string;
    end_year: string;
    institution: string;
}

interface ExpertData {
    existingData: {
        name: string;
        email: string;
        headline?: string;
        work_experiences: WorkExperience[];
        education: Education[];
    };
    enrichedData: {
        name: string;
        email: string;
        headline?: string;
        work_experiences: WorkExperience[];
        education: Education[];
    };
}
const ExpertsListPage = () => {
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchQueryConst, setSearchQueryConst] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const expertId = parseInt(searchParams.get("id") || "nan");
    const linkedin_url = searchParams.get("link");
    const [responseData, setResponseData] = useState<ExpertData | null>(null);
    const [localIdentityData, setLocalIdentityData] = useState<any>({ existingData: { name: "", headline: "" }, enrichedData: { name: "", headline: "" } });
    const finalWorkDataRef = useRef<any[][]>([]);
    let finalWorkData = finalWorkDataRef.current;
    useEffect(() => {
        finalWorkData = finalWorkDataRef.current;
    }, [finalWorkDataRef.current]);
    const setFinalWorkData = (newData: any[][]) => {
        finalWorkDataRef.current = newData;
    };
    useEffect(() => {
        if (!searchQuery || searchQuery.trim() === "") {
            return;
        }
        handleSubmit();
    }, [searchQuery])
    const [isWorkExperienceEditable, setIsWorkExperienceEditable] = useState<boolean[][]>([]);
    const [finalEducationData, setFinalEducationData] = useState<any[][]>([]);
    const [isWorkExperienceCollapsed, setIsWorkExperienceCollapsed] = useState(true);
    const [isEducationCollapsed, setIsEducationCollapsed] = useState(true);
    const [isEducationEditable, setIsEducationEditable] = useState<boolean[][]>([]);
    const [isExistingNameEditable, setIsExistingNameEditable] = useState(true);
    const [isExistingHeadlineEditable, setIsExistingHeadlineEditable] = useState(true);
    const [showReviewPage, setShowReviewPage] = useState<boolean>(false);
    const [payload, setPayload] = useState<any>({});
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        setSearchQuery(linkedin_url || "");
        if (!expertId) {
            navigate(-1);
        }
    }, [expertId, linkedin_url])
    const getWorkEditableStatus = (index: number, i: number): boolean => {
        if (isWorkExperienceEditable.length <= index || isWorkExperienceEditable[index].length < 2) {
            return false;
        }
        return isWorkExperienceEditable[index][i];
    }
    const getEducationEditableStatus = (index: number, i: number): boolean => {
        if (isEducationEditable.length <= index || isEducationEditable[index].length < 2) {
            return false;
        }
        return isEducationEditable[index][i];
    }
    const toggleEducationEditable = (index: number, i: number) => {
        setIsEducationEditable((prev) => {
            const newState = [...prev];
            newState[index][i] = !newState[index][i];
            return newState;
        });
    };
    const toggleWorkExperienceEditable = (index: number, i: number) => {
        setIsWorkExperienceEditable((prev) => {
            const newState = [...prev];
            newState[index][i] = !newState[index][i];
            return newState;
        });
    };
    const parseNewData = (data: any, newData: any): any => {
        const keys = Object.keys(newData);
        const newParsedData = { ...data };
        for (let key of keys) {
            newParsedData[key] = newData[key] || data[key];
        }
        return newParsedData;
    }
    function formatDate(date: any): string {
        const year = date?.$y;
        const month = String(date?.$M + 1).padStart(2, '0'); // Months are 0-indexed
        return `${year}-${month}`;
    }
    const parseNewWorkData = (data: WorkExperience, newData: any): WorkExperience => {
        const newParsedData = { ...newData };
        newParsedData.company = newData.company.label;
        newParsedData.fk_company = newData.company.value;
        newParsedData.start_date = formatDate(newData.start_date);
        newParsedData.end_date = formatDate(newData.end_date);
        console.log(newParsedData);
        return parseNewData(data, newParsedData);
    }
    const handleLocalWorkDataChange = (index: number, i: number, value: any) => {
        const newData = [...finalWorkData];
        const finalParsedData = parseNewWorkData(newData[index][i], value);
        newData[index][i] = finalParsedData;
        setFinalWorkData(newData);
    }
    const parseNewEduData = (data: Education, newData: any): Education => {
        const newParsedData = { ...newData };
        newParsedData.start_year = `${newData.start_year.$y}`;
        newParsedData.end_year = `${newData.end_year.$y}`;
        return parseNewData(data, newParsedData);
    }
    const handleLocalEduDataChange = (index: number, i: number, value: any) => {
        const newData = [...finalEducationData];
        const finalParsedData = parseNewEduData(newData[index][i], value);
        newData[index][i] = finalParsedData;
        setFinalEducationData(newData);
    }
    const handleLocalExistingNameChange = async (e: any) => {
        if (isExistingNameEditable) {
            setLocalIdentityData({
                ...localIdentityData,
                existingData: {
                    ...localIdentityData.existingData,
                    name: e.target.value,
                },
            });
        }
    }
    const handleLocalExistingHeadlineChange = async (e: any) => {
        if (isExistingHeadlineEditable) {
            setLocalIdentityData({
                ...localIdentityData,
                existingData: {
                    ...localIdentityData.existingData,
                    headline: e.target.value,
                },
            });
        }
    }
    const handleLocalEnrichedNameChange = async (e: any) => {
        if (!isExistingNameEditable) {
            setLocalIdentityData({
                ...localIdentityData,
                enrichedData: {
                    ...localIdentityData.enrichedData,
                    name: e.target.value,
                },
            });
        }
    }
    const handleLocalEnrichedHeadlineChange = async (e: any) => {
        if (!isExistingHeadlineEditable) {
            setLocalIdentityData({
                ...localIdentityData,
                enrichedData: {
                    ...localIdentityData.enrichedData,
                    headline: e.target.value,
                },
            });
        }
    }
    const workExpErrorHandler = (work: WorkExperience): boolean => {
        if (!work.company || work.company.trim() === "" || !work.fk_company) {
            console.log(work);
            enqueueSnackbar(EXPERT_API_RESPONSES.COMPANY_IS_REQUIRED, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return false;
        }
        if (!work.designation || work.designation.trim() === "") {
            enqueueSnackbar(EXPERT_API_RESPONSES.DESIGNATION_IS_REQUIRED, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return false;
        }
        if (!work.start_date) {
            enqueueSnackbar(EXPERT_API_RESPONSES.START_DATE_IS_REQUIRED, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return false;
        }
        if (typeof work.currently_works_here !== "boolean") {
            enqueueSnackbar(EXPERT_API_RESPONSES.CURRENTLY_WORKS_HERE_IS_REQUIRED, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return false;
        }
        if (!work.currently_works_here && !work.end_date) {
            enqueueSnackbar(EXPERT_API_RESPONSES.END_DATE_IS_REQUIRED, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return false;
        }
        return true;
    }
    const educationErrorHandler = (edu: Education): boolean => {
        if (!edu.course || edu.course.trim() === "") {
            enqueueSnackbar(EXPERT_API_RESPONSES.COURSE_IS_REQUIRED, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return false;
        }
        if (!edu.institution || edu.institution.trim() === "") {
            enqueueSnackbar(EXPERT_API_RESPONSES.INSTITUTION_IS_REQUIRED, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return false;
        }
        if (!edu.start_year) {
            enqueueSnackbar(EXPERT_API_RESPONSES.START_YEAR_IS_REQUIRED, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return false;
        }
        if (!edu.end_year) {
            enqueueSnackbar(EXPERT_API_RESPONSES.END_YEAR_IS_REQUIRED, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        if (!searchQuery) {
            enqueueSnackbar(EXPERT_API_RESPONSES.PLEASE_ENTER_URL, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            return;
        }
        try {
            setLoading(true);
            const data = await fetchExpertData(searchQuery, expertId);
            if (data) {
                setResponseData(data);

            } else {
                enqueueSnackbar(EXPERT_API_RESPONSES.FAILED_TO_FETCH_DATA, {
                    variant: "error",
                    autoHideDuration: 2000,
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                });
            }
        } catch (error) {
            enqueueSnackbar(EXPERT_API_RESPONSES.ERROR_OCCURRED_WHILE_FETCHING_DATA, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
        } finally {
            setLoading(false);
            setSearchQueryConst(searchQuery);
        }
    };

    const handleUpdate = async () => {
        try {
            let finalWorkExperiencesPayload = [];
            let finalEducationPayload = [];
            for (let index = 0; index < finalWorkData.length; index++) {
                const work = finalWorkData[index];
                if (work[0] && isWorkExperienceEditable[index][0]) {
                    if (!workExpErrorHandler(work[0])) {
                        return;
                    };
                    finalWorkExperiencesPayload.push(work[0]);
                }
                if (work[1] && isWorkExperienceEditable[index][1]) {
                    if (!workExpErrorHandler(work[1])) {
                        return;
                    };
                    finalWorkExperiencesPayload.push(work[1]);
                }
            }
            for (let index = 0; index < finalEducationData.length; index++) {
                const edu = finalEducationData[index];
                if (edu[0] && isEducationEditable[index][0]) {
                    if (!educationErrorHandler(edu[0])) {
                        return;
                    }
                    finalEducationPayload.push(edu[0]);
                }
                if (edu[1] && isEducationEditable[index][1]) {
                    if (!educationErrorHandler(edu[1])) {
                        return;
                    }
                    finalEducationPayload.push(edu[1]);
                }
            }
            for (let index = 0; index < finalWorkExperiencesPayload.length; index++) {
                delete finalWorkExperiencesPayload[index].initial_company
            }
            let name = localIdentityData.existingData.name;
            let headline = localIdentityData.existingData.headline;
            if (!isExistingNameEditable) {
                name = localIdentityData.enrichedData.name;
            }
            if (!isExistingHeadlineEditable) {
                headline = localIdentityData.enrichedData.headline;
            }
            const payload = {
                name: name,
                expertId: expertId,
                headline: headline,
                source_link: linkedin_url,
                education: finalEducationPayload,
                work_experiences: finalWorkExperiencesPayload,
            };
            setPayload(payload);
            setShowReviewPage(true);
        } catch (error) {
            enqueueSnackbar(EXPERT_API_RESPONSES.ERROR_OCCURED_WHILE_UPDATING_DATA, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
        }
    };
    useEffect(() => {
        if (responseData) {
            handleInitializeLocalData();
            workExperienceParser();
            educationParser();
        }
    }, [responseData]);

    const handleInitializeLocalData = () => {
        const localData = {
            existingData: {
                name: responseData?.existingData.name,
                headline: responseData?.existingData.headline,
            },
            enrichedData: {
                name: responseData?.enrichedData.name,
                headline: responseData?.enrichedData.headline,
            }
        }
        setLocalIdentityData(localData);
    }

    const compareWithKeys = (a: any, b: any, keys: string[]): boolean => {
        for (let key of keys) {
            if (a[key] !== b[key]) {
                return false;
            }
        }
        return true;
    }
    const compareDate = (a: string, b: string): boolean => {
        return new Date(a) > new Date(b);
    }
    const workExperienceParser = () => {
        let existingWorkExp: WorkExperience[] = responseData?.existingData.work_experiences || [];
        let enrichedWorkExp: WorkExperience[] = responseData?.enrichedData.work_experiences || [];
        let finalData: any[][] = [];
        let left = 0;
        let right = 0;
        for (let index = 0; index < enrichedWorkExp.length; index++) {
            enrichedWorkExp[index].initial_company = enrichedWorkExp[index].company;
        }
        while (left < existingWorkExp.length && right < enrichedWorkExp.length) {
            if (compareWithKeys(existingWorkExp[left], enrichedWorkExp[right], workExpCompareKeys)) {
                finalData.push([existingWorkExp[left], enrichedWorkExp[right]]);
                left++;
                right++;
            } else {
                if (compareDate(existingWorkExp[left].start_date, enrichedWorkExp[right].start_date)) {
                    finalData.push([existingWorkExp[left], null]);
                    left++;
                } else {
                    finalData.push([null, enrichedWorkExp[right]]);
                    right++;
                }
            }
        }
        while (left < existingWorkExp.length) {
            finalData.push([existingWorkExp[left], null]);
            left++;
        }
        while (right < enrichedWorkExp.length) {
            finalData.push([null, enrichedWorkExp[right]]);
            right++;
        }
        const workEditableFalseData = Array(finalData.length);
        for (let index = 0; index < finalData.length; index++) {
            for (let i = 0; i <= 1; i++) {
                if (!finalData[index][i]) {
                    continue;
                }
                finalData[index][i].start_date = formatDate(dayjs(finalData[index][i].start_date));
                finalData[index][i].end_date = formatDate(dayjs(finalData[index][i].end_date));
                console.log(finalData[index][i].start_date, finalData[index][i].end_date);
            }
        }
        for (let index = 0; index < finalData.length; index++) {
            const newFalsePair = [false, false];
            workEditableFalseData[index] = newFalsePair;
        }
        console.log(finalData);

        setIsWorkExperienceEditable(workEditableFalseData);
        setFinalWorkData(finalData);
    };
    const educationParser = () => {
        let existingEducation: Education[] = responseData?.existingData.education || [];
        let enrichedEducation: Education[] = responseData?.enrichedData.education || [];
        let finalData: any[][] = [];
        let left = 0;
        let right = 0;

        while (left < existingEducation.length && right < enrichedEducation.length) {
            if (compareWithKeys(existingEducation[left], enrichedEducation[right], educationCompareKeys)) {
                finalData.push([existingEducation[left], enrichedEducation[right]]);
                left++;
                right++;
            } else {
                if (compareDate(existingEducation[left].start_year, enrichedEducation[right].start_year)) {
                    finalData.push([existingEducation[left], null]);
                    left++;
                } else {
                    finalData.push([null, enrichedEducation[right]]);
                    right++;
                }
            }
        }

        while (left < existingEducation.length) {
            finalData.push([existingEducation[left], null]);
            left++;
        }

        while (right < enrichedEducation.length) {
            finalData.push([null, enrichedEducation[right]]);
            right++;
        }
        const educationEditableFalseData = Array(finalData.length);
        for (let index = 0; index < finalData.length; index++) {
            const newFalsePair = [false, false];
            educationEditableFalseData[index] = newFalsePair;
        }
        setIsEducationEditable(educationEditableFalseData);
        setFinalEducationData(finalData);
    };
    const renderWorkExperience = (workArray: any[], index: number, i: number) => {
        const work = workArray[i];
        if (!work) {
            return (
                <>
                </>)
        }
        return (
            <>
                <Box sx={{ marginTop: 2 }} key={index}>
                    <Card sx={{
                        boxShadow: 3, marginBottom: 2, borderRadius: '25px',
                        border: getWorkEditableStatus(index, i) ? '2px solid #ef9e48' : 'none',

                    }}>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ position: 'relative', marginBottom: 1 }}>
                                {i === 1 ? (
                                    <Typography variant="body1" sx={{ fontWeight: 500, marginLeft: 2 }}>
                                        Company: <b>{work.initial_company}</b>
                                    </Typography>
                                ) : <Typography></Typography>}
                                <IconButton
                                    sx={{
                                        // position: 'absolute',
                                        // top: '10px',
                                        // right: '10px',
                                        color: getWorkEditableStatus(index, i) ? "#fff" : "#37B498",
                                        backgroundColor: getWorkEditableStatus(index, i)
                                            ? "#37B498"
                                            : "rgba(55, 180, 152, 0.12)",
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "10px",
                                        "&:hover": {
                                            backgroundColor: getWorkEditableStatus(index, i)
                                                ? "#37B498"
                                                : "rgba(55, 180, 152, 0.12)",
                                        },
                                    }}
                                    onClick={() => toggleWorkExperienceEditable(index, i)}
                                >
                                    <CheckIcon />
                                </IconButton>
                            </Box>
                            <Box sx={{ marginTop: '40px' }}>
                                <ExperienceForm
                                    defaultValues={{
                                        company: i === 1 ? "" : work.company,
                                        designation: work.designation,
                                        location: work.location,
                                        division: work.division,
                                        currently_works_here: work.currently_works_here,
                                        job_description: work.job_description,
                                        start_date: work.start_date ? new Date(work.start_date) : null,
                                        end_date: work.end_date ? new Date(work.end_date) : null,
                                    }}
                                    setOnChange={(value: any) => { handleLocalWorkDataChange(index, i, value) }}
                                    index={index}
                                    showButtons={false}
                                    isEditable={getWorkEditableStatus(index, i)}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </>
        )
    };
    const renderEducation = (eduArray: any[], index: number, columnIndex: number) => {
        const edu = eduArray[columnIndex];
        if (!edu) {
            return <Box key={index} height={275}></Box>;
        }
        return (
            <Box key={index} sx={{ marginTop: 2 }}>
                <Card
                    sx={{
                        boxShadow: 3,
                        marginBottom: 2,
                        borderRadius: '25px',
                        border: getEducationEditableStatus(index, columnIndex) ? '2px solid #ef9e48' : 'none',
                    }}>
                    <CardContent>
                        <Box display="flex" alignItems="flex-start" sx={{ position: 'relative', marginBottom: 1 }}>
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    color: getEducationEditableStatus(index, columnIndex) ? "#fff" : "#37B498",
                                    backgroundColor: getEducationEditableStatus(index, columnIndex)
                                        ? "#37B498"
                                        : "rgba(55, 180, 152, 0.12)",
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "10px",
                                    "&:hover": {
                                        backgroundColor: getEducationEditableStatus(index, columnIndex)
                                            ? "#37B498"
                                            : "rgba(55, 180, 152, 0.12)",
                                    },
                                }}
                                onClick={() => toggleEducationEditable(index, columnIndex)}
                            >
                                <CheckIcon />
                            </IconButton>

                        </Box>
                        <Box sx={{ marginTop: '40px' }}>
                            <EducationForm
                                defaultValues={{
                                    course: edu.course,
                                    institution: edu.institution,
                                    start_year: edu.start_year,
                                    end_year: edu.end_year,
                                }}
                                index={index}
                                setOnChange={(value: any) => { handleLocalEduDataChange(index, columnIndex, value) }}
                                showButtons={false}
                                isEditable={getEducationEditableStatus(index, columnIndex)}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        );
    };
    return (
        <PageLayout>
            <ExpertsListPageHeader
                setFilterPayload={(callback) => {
                    const newSearchParams = new URLSearchParams(searchParams.toString());
                    callback(newSearchParams);
                    setSearchParams(newSearchParams);
                }}
            />
            {showReviewPage && <FullPageReview
                closeReview={() => { setShowReviewPage(false) }}
                payload={payload}
            />}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px', paddingBottom: "0px" }}>
                <Typography variant="body2" sx={{ fontWeight: 500, marginBottom: 2 }}>
                    Expert ID : <b>{expertId}</b>
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 500, marginBottom: 2 }}>
                    Linkedin URL : <b>{searchQueryConst}</b>
                </Typography>
            </div>
            {loading ? (
                <CardsLoadingScreen height="300px" />
            ) : (
                responseData && (
                    <Box className="moderate-profile-container" sx={{ padding: "20px", backgroundColor: '#F0F0F0 !important', overflowY: 'auto', height: 'calc(100vh - 155px)' }}>

                        <Grid container spacing={2}>
                            <Grid item xs={6} sx={{ borderRight: '2px solid #b7b7b7' }}>
                                <Typography variant="h6" sx={{ backgroundColor: '#E4E4E4', paddingLeft: '5px', borderRadius: '9px', border: '1.5px dashed grey', marginRight: 3 }}>Existing Data</Typography>
                                <Box sx={{ marginTop: 2 }}>

                                    <Card sx={{
                                        boxShadow: 3, marginBottom: 2, borderRadius: '25px', border: '2px solid transparent', '&:hover': {
                                            borderColor: '#EC9324',
                                        },
                                        marginRight: 2
                                    }}>
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    marginBottom: 1,
                                                    paddingTop: '11px'
                                                }}
                                            >
                                                <TextField
                                                    label=""
                                                    placeholder="Name"
                                                    fullWidth
                                                    disabled={!isExistingNameEditable}
                                                    value={localIdentityData.existingData.name}
                                                    onChange={handleLocalExistingNameChange}
                                                    size="small"
                                                    sx={{
                                                        flexGrow: 1,
                                                        marginRight: 1,
                                                        border: isExistingNameEditable ? '2px solid #ef9e48' : '2px solid transparent',
                                                        '& .MuiInputBase-root': {
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        color: isExistingNameEditable ? "#fff" : "#37B498",
                                                        backgroundColor: isExistingNameEditable
                                                            ? "#37B498"
                                                            : "rgba(55, 180, 152, 0.12)",
                                                        height: "30px",
                                                        width: "30px",
                                                        borderRadius: "10px",
                                                        "&:hover": {
                                                            backgroundColor: isExistingNameEditable
                                                                ? "#37B498"
                                                                : "rgba(55, 180, 152, 0.12)",
                                                        },
                                                    }}
                                                    onClick={() => setIsExistingNameEditable(!isExistingNameEditable)}
                                                >
                                                    <CheckIcon />
                                                </IconButton>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',

                                                }}
                                            >
                                                <TextField
                                                    label=""
                                                    placeholder="Headline"
                                                    fullWidth
                                                    disabled={!isExistingHeadlineEditable}
                                                    value={localIdentityData.existingData.headline}
                                                    onChange={handleLocalExistingHeadlineChange}
                                                    size="small"
                                                    sx={{
                                                        flexGrow: 1,
                                                        marginRight: 1,
                                                        border: isExistingHeadlineEditable ? '2px solid #ef9e48' : '2px solid transparent',
                                                        '& .MuiInputBase-root': {
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        color: isExistingHeadlineEditable ? "#fff" : "#37B498",
                                                        backgroundColor: isExistingHeadlineEditable
                                                            ? "#37B498"
                                                            : "rgba(55, 180, 152, 0.12)",
                                                        height: "30px",
                                                        width: "30px",
                                                        borderRadius: "10px",
                                                        "&:hover": {
                                                            backgroundColor: isExistingHeadlineEditable
                                                                ? "#37B498"
                                                                : "rgba(55, 180, 152, 0.12)",
                                                        },
                                                    }}
                                                    onClick={() => setIsExistingHeadlineEditable(!isExistingHeadlineEditable)}
                                                >
                                                    <CheckIcon />
                                                </IconButton>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        backgroundColor: '#E4E4E4',
                                        paddingLeft: '5px',
                                        borderRadius: '9px',
                                        border: '1.5px dashed grey'

                                    }}
                                >
                                    Enriched Data
                                </Typography>

                                <Box sx={{ marginTop: 2 }}>
                                    <Card sx={{
                                        boxShadow: 3, marginBottom: 2, borderRadius: '25px', border: '2px solid transparent', '&:hover': {
                                            borderColor: '#EC9324',
                                        },
                                    }}>
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    marginBottom: 1,
                                                    paddingTop: '11px'

                                                }}
                                            >
                                                <TextField
                                                    label=""
                                                    placeholder="Name"
                                                    fullWidth
                                                    disabled={isExistingNameEditable}
                                                    value={localIdentityData.enrichedData.name}
                                                    onChange={handleLocalEnrichedNameChange}
                                                    size="small"
                                                    sx={{
                                                        flexGrow: 1,
                                                        marginRight: 1,
                                                        border: !isExistingNameEditable ? '2px solid #ef9e48' : '2px solid transparent',
                                                        '& .MuiInputBase-root': {
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        color: !isExistingNameEditable ? "#fff" : "#37B498",
                                                        backgroundColor: !isExistingNameEditable
                                                            ? "#37B498"
                                                            : "rgba(55, 180, 152, 0.12)",
                                                        height: "30px",
                                                        width: "30px",
                                                        borderRadius: "10px",
                                                        "&:hover": {
                                                            backgroundColor: !isExistingNameEditable
                                                                ? "#37B498"
                                                                : "rgba(55, 180, 152, 0.12)",
                                                        },
                                                    }}
                                                    onClick={() => setIsExistingNameEditable(!isExistingNameEditable)}
                                                >
                                                    <CheckIcon />
                                                </IconButton>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',

                                                }}
                                            >
                                                <TextField
                                                    label=""
                                                    placeholder="Headline"
                                                    fullWidth
                                                    disabled={isExistingHeadlineEditable}
                                                    value={localIdentityData.enrichedData.headline}
                                                    onChange={handleLocalEnrichedHeadlineChange}
                                                    size="small"
                                                    sx={{
                                                        flexGrow: 1,
                                                        marginRight: 1,
                                                        border: !isExistingHeadlineEditable ? '2px solid #ef9e48' : '2px solid transparent',
                                                        '& .MuiInputBase-root': {
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        color: !isExistingHeadlineEditable ? "#fff" : "#37B498",
                                                        backgroundColor: !isExistingHeadlineEditable
                                                            ? "#37B498"
                                                            : "rgba(55, 180, 152, 0.12)",
                                                        height: "30px",
                                                        width: "30px",
                                                        borderRadius: "10px",
                                                        "&:hover": {
                                                            backgroundColor: !isExistingHeadlineEditable
                                                                ? "#37B498"
                                                                : "rgba(55, 180, 152, 0.12)",
                                                        },
                                                    }}
                                                    onClick={() => setIsExistingHeadlineEditable(!isExistingHeadlineEditable)}
                                                >
                                                    <CheckIcon />
                                                </IconButton>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} sx={{ marginTop: 2 }}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        backgroundColor: '#E4E4E4',
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "5px",
                                        borderRadius: '10px',
                                        border: '1.5px dashed grey',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setIsWorkExperienceCollapsed(!isWorkExperienceCollapsed)}
                                >
                                    <Typography className="work-history-text" sx={{ flexGrow: 1, fontWeight: 500 }}>Work Experience</Typography>
                                    <Box>
                                        {isWorkExperienceCollapsed ? (
                                            <ExpandMore sx={{ maxWidth: '100%', verticalAlign: 'middle' }} />
                                        ) : (
                                            <ExpandLess sx={{ maxWidth: '100%', verticalAlign: 'middle' }} />
                                        )}
                                    </Box>
                                </Typography>
                                <Collapse in={!isWorkExperienceCollapsed}>
                                    <Grid container sx={{ display: 'flex', marginTop: '2rem' }}>
                                        {finalWorkData.map((work: any, index: number) => {
                                            return (
                                                <>
                                                    <Grid item xs={6} sx={{ paddingRight: 2, borderRight: '2px solid #b7b7b7' }}>
                                                        <Box sx={{ marginTop: 2 }}>
                                                            {renderWorkExperience(work, index, 0)}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{ paddingLeft: 2 }}>
                                                        <Box sx={{ marginTop: 2 }}>
                                                            {renderWorkExperience(work, index, 1)}
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </Grid>
                                </Collapse>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        backgroundColor: '#E4E4E4',
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "5px",
                                        borderRadius: '10px',
                                        border: '1.5px dashed grey',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setIsEducationCollapsed(!isEducationCollapsed)}
                                >
                                    <Typography className="education-text" sx={{ flexGrow: 1, fontWeight: 500 }}>Education</Typography>
                                    <Box>
                                        {isEducationCollapsed ? (
                                            <ExpandMore sx={{ maxWidth: '100%', verticalAlign: 'middle' }} />
                                        ) : (
                                            <ExpandLess sx={{ maxWidth: '100%', verticalAlign: 'middle' }} />
                                        )}
                                    </Box>
                                </Typography>
                                <Collapse in={!isEducationCollapsed}>
                                    <Grid container sx={{ display: 'flex', marginTop: '2rem' }}>
                                        {finalEducationData.map((data: any, index: number) => {
                                            return (
                                                <>
                                                    <Grid item xs={6} sx={{ paddingRight: 2, borderRight: '2px solid #b7b7b7' }}>
                                                        <Box sx={{ marginTop: 2 }}>
                                                            {renderEducation(data, index, 0)}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{ paddingLeft: 2 }}>
                                                        <Box sx={{ marginTop: 2 }}>
                                                            {renderEducation(data, index, 1)}
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )

                                        })}

                                    </Grid>
                                </Collapse>
                            </Grid>
                        </Grid>
                        <Divider sx={{ marginTop: 2 }} />
                        <Grid className="select-profile-btn" sx={{ marginTop: "20px" }}>
                            <Button className="profile-update-btn" onClick={handleUpdate}>Update</Button>
                        </Grid>
                    </Box>
                )
            )
            }
        </PageLayout>
    );
};

export default ExpertsListPage;
